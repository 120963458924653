import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";

import { UnityIcon } from "@/components/DesignSystem";
import { ReactComponent as ExclamationTriangleSolid } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-triangle-solid.svg";
import { Tooltip } from "antd";
import getOr from "lodash/fp/getOr";
import join from "lodash/fp/join";
import map from "lodash/fp/map";
import pipe from "lodash/fp/pipe";
import split from "lodash/fp/split";
import React from "react";
import { ALERT_TRIGGER_STATE_BY_TYPE } from "../../CreateJobTaskAlert/constants";
import AlertSeverityFormatter from "../AlertSeverityFormatter";

export const WarningIconSolid = ({ display, tooltip }) => {
    if (!display) return null;

    return (
        <Tooltip tooltip={tooltip}>
            <UnityIcon
                component={ExclamationTriangleSolid}
                style={{
                    // color: colors.warning.primary, // TODO: unify
                    color: "#FAAD14",
                    fontSize: "15px",
                }}
            />
        </Tooltip>
    );
};

export const createAlertsColumns = () => [
    {
        type: fieldTypes.TEXT,
        label: t("alerts-list.header.name"),
        name: "name",
    },
    {
        type: fieldTypes.OPTION,
        label: t("alerts-list.header.module"),
        name: "module",
        options: [
            { value: null, label: "All" },
            { value: "PLATFORM", label: "PLATFORM" },
            { value: "PARTITION", label: "PARTITION" },
            { value: "INTEGRATION", label: "INTEGRATION" },
            { value: "ACCELERATOR", label: "ACCELERATOR" },
            { value: "WORKFLOW", label: "WORKFLOW" },
        ],
    },
    {
        type: fieldTypes.DATETIME,
        label: t("alerts-list.header.fired"),
        name: "fired",
        render: (text, record) => {
            return (
                <>
                    <WarningIconSolid
                        display={!!record.alerts?.fired_ALERT_TYPE}
                        tooltip={record.deliveryMessage}
                    />
                    <FormattedDateTime>{text}</FormattedDateTime>
                </>
            );
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("alerts-list.header.partition"),
        name: "serialNumber",
    },
    {
        type: fieldTypes.TEXT,
        label: t("alerts-list.header.instance-name"),
        name: "instanceName",
    },
    {
        type: fieldTypes.TEXT,
        label: t("alerts-list.header.alert-type"),
        name: "alertType",
    },

    // Alert - "INSTANCE_CHECK" - showAllAlertOptions === true
    {
        type: fieldTypes.OPTION,
        label: t("alerts-list.header.severity"),
        name: "severity",
        visible: false,
        panelProps: { hideEmpty: true },
        visibleInPanel: true,
        options: [
            { value: null, label: "All" },
            { value: "CRITICAL", label: "Critical" },
            { value: "HIGH", label: "High" },
            { value: "MEDIUM", label: "Medium" },
            { value: "LOW", label: "Low" },
        ],
        render: (text, record) =>
            record.severity ? (
                <AlertSeverityFormatter value={record.severity} />
            ) : null,
    },
    {
        type: fieldTypes.TEXT,
        label: t("alerts-list.header.threshold"),
        name: "threshold",
        visible: false,
        panelProps: { hideEmpty: true },
        visibleInPanel: true,
    },
    {
        type: fieldTypes.TEXT,
        label: t("alerts-list.header.value"),
        name: "value",
        visible: false,
        panelProps: { hideEmpty: true },
        visibleInPanel: true,
    },
    // Notification - "PARTITION_JOB", "ROUTE_CHECK" - showAllAlertOptions === true/false
    {
        type: fieldTypes.TEXT,
        label: t("alerts-list.header.trigger-value"),
        name: "triggerValue",
        visible: false,
        panelProps: { hideEmpty: true },
        visibleInPanel: true,
        render: (triggerValue, { alertType }) => {
            if (!triggerValue) return null;

            return pipe(
                split(","),
                map(value =>
                    getOr(
                        value,
                        [alertType, value],
                        ALERT_TRIGGER_STATE_BY_TYPE,
                    ),
                ),
                join(","),
            )(triggerValue);
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("alerts-list.header.target"),
        name: "target",
        visible: false,
        panelProps: { hideEmpty: true },
        visibleInPanel: true,
    },
    {
        type: fieldTypes.TEXT,
        label: t("alerts-list.header.target-type"),
        name: "targetType",
        visible: false,
        panelProps: { hideEmpty: true },
        visibleInPanel: true,
    },
];
